import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 40px;
  align-self: center;
  width: 100%;
  padding: 0 48px;
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 32px;
  align-self: center;
  width: 100%;
  padding: 48px 48px 0;
`;

export const TitleHeader = styled.h2`
  font-weight: 600;
  font-size: 28.8px;
  line-height: 38px;
  color: #00205b;
`;

export const Chip = styled.span`
  align-self: center;
  border: 1px solid #0cba66;
  border-radius: 100px;
  padding: 3.5px 10px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #0cba66;
`;

export const Index = styled.div`
  display: flex;
  flex-direction: column;
  width: 288px;
  max-height: 496px;
  overflow: hidden;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 12.6px;
  line-height: 17px;
  color: #3d5582;
  margin-bottom: 16px;
`;

export const Scroll = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const TopicContainer = styled.div`
  padding-block: 12px;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  padding-left: ${(props) => (props.topic === 'h3' ? '32px' : 0)};
`;

export const Tag = styled.span`
  background: #e5e8ee;
  border-radius: 100px;
  padding: 3.5px 11.5px;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const Topic = styled.span`
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  color: #3d5582;
`;

export const Editor = styled.div`
  width: calc(100% - 288px);
`;

export const FooterActions = styled.div`
  border-top: 1px solid #e5e8ee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  margin: 0 32px 48px;
  z-index: 1000;
`;

export const BoxButtons = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;

export const ButtonPreview = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;

export const WarningSave = styled.div`
  font-weight: 400;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
  align-self: center;
  width: 100%;
  padding: 0 48px 30px;
`;
