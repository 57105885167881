import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '~/assets/drops-removefile-logo.svg';

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 40px;
  align-self: center;
  width: 100%;
  padding: 0 48px;
`;

export const Index = styled.div`
  display: flex;
  flex-direction: column;
  width: 288px;
  height: calc(100vh - 478px);
  min-height: 480px;
  overflow: hidden;
`;

export const Scroll = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
  text-transform: uppercase;
  color: #3d5582;
`;

export const TopicContainer = styled.div`
  padding-block: 12px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;
`;

export const TopicLeftContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const SubtopicContainer = styled(TopicLeftContainer)`
  padding-block: 12px;
  padding-left: 32px;
`;

export const Tag = styled.span`
  background: ${(props) => (props.selected ? '#00205B' : '#e5e8ee')};
  border-radius: 100px;
  padding: 3.5px 11.5px;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: ${(props) => (props.selected ? '#fff' : '#00205b')};
  height: 24px;
`;

export const CfaTag = styled.span`
  background: #00205b;
  border-radius: 8px;
  padding: 0 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  height: 24px;
`;

export const Name = styled.span`
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  font-size: 12.6px;
  line-height: 17px;
  color: ${(props) => (props.selected ? '#00205B' : '#3d5582')};
  display: flex;
  align-items: center;
`;

export const TextContainer = styled.div`
  width: calc(100% - 288px);
  background: #ffffff;
  border-radius: 8px;
  height: calc(100vh - 478px);
  min-height: 480px;
  position: relative;
  overflow: hidden;
`;

export const ScrollTextContainer = styled.div`
  overflow: auto;
  height: calc(100vh - 478px);
  min-height: 480px;
  padding-bottom: 96px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const CfaFooter = styled.div`
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 24px 32px 32px;
  display: flex;
  gap: 24px;
  align-items: center;
`;

export const CfaSelectContainer = styled.div`
  width: 100%;
  max-width: 480px;
`;

export const RemoveCfaButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 14px;
  gap: 10px;
  height: 40px;
  border: 2px solid #e5e8ee;
  border-radius: 320px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #019d8a;
  background: #fff;
  flex-shrink: 0;
  :hover {
    border-color: #d1d7e1;
  }
  :disabled {
    color: #8f9db7;
    background: #e5e8ee;
    pointer-events: none;
    path {
      fill: #8f9db7;
    }
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  path {
    fill: #019d8a;
  }
`;

export const SelectedTitle = styled.div`
  font-size: 40px;
  line-height: 52px;
  color: #00205b;
  padding: 32px 32px 0;
`;

export const SelectedText = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: #181a1e;
  padding: 0 32px 32px;
`;

export const SubSectionTitle = styled.div`
  font-size: 22px;
  line-height: 30px;
  color: #00205b;
  padding: 16px 32px 0;
`;

export const FooterActions = styled.div`
  border-top: 1px solid #e5e8ee;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  margin: 0 32px 16px;
  z-index: 1000;
`;

export const BoxButtons = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;

export const ButtonPreview = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;

  :hover {
    background: #e5faf7;
  }
`;
