import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
`;

export const WrapContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 48px;
  max-width: 1388px;
`;

export const ContainerNewTrack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type='file'] {
    display: none;
  }
`;

export const Space = styled.div`
  height: 15px;
`;

export const FormContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  width: 100%;
  align-self: stretch;
`;

export const StepContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 32px 56px;
  border-top: 1px solid #e5e8ee;
`;

export const StepText = styled.span`
  max-width: 350px;
  font-style: normal;
  font-weight: 600;
  font-size: 22.8px;
  line-height: 30px;
  color: #00205b;
`;

export const StepOder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 40px;
  background: #01c3aa;
  color: #ffffff;
  border-radius: 1000px;
  font-style: normal;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 36px;
  color: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 21px;
  margin: 16px 0px;
  padding-top: 20px;
`;

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const RowContainerInputs = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  width: 100%;
`;

export const CoverContainer = styled(RowContainerInputs)`
  padding-top: 10px;
  gap: 15px;
  :focus-within {
    background-color: #000;
  }
`;
export const FilesContainer = styled(RowContainerInputs)`
  padding-top: 10px;
  height: 100px;
  gap: 4px;
`;

export const Rows = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
  margin-bottom: 20px;

  label {
    width: 100%;
  }
`;

export const RowsCenter = styled(Rows)`
  text-align: center;
  margin-bottom: 5px;
`;

export const RowMinor = styled.div`
  margin-top: -15px;
  margin-bottom: 20px;
  color: #4f5154;

  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export const ContainerInstitution = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  max-width: 544px;
`;

export const ContainerAlternatives = styled.div`
  width: 100%;
  max-width: 544px;

  input {
    width: 100%;
    height: 25px;
    border: none;
    background: #000;
    color: #3d5582;
    font-size: 10px;
`;

export const CustomInputFile = styled.div`
  display: flex;
  border: 3px solid #e5e8ee;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  max-width: 544px;

  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :active {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
    color: #01cfb5;
  }
`;

export const FileName = styled.div`
  background: #f0f2f5;
  border: transparent;
  border-radius: 6px 0 0 6px;
  height: 48px;
  width: 100%;
  padding-inline: 20px;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #3d5582;
  :hover {
    background-color: #fff;
  }
`;

export const ButtonSelectFile = styled.div`
  border-left: 2px solid #e5e8ee;
  border-radius: 0 8px 8px 0;
  width: 100%;
  max-width: 127px;
  height: 48px;
  padding: 13px 20px;
  background-color: #fff;
  font-size: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 0px;
  position: relative;
`;

export const FileText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #3d5582;
`;
export const LastFileText = styled(FileText)`
  font-size: 12.2px;
  line-height: 0px;
`;

export const LastStep = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  border-top: 1px solid #e5e8ee;
`;

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
`;

export const FooterTrack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 24px;
  width: calc(100% - 64px);
  margin: 0 32px;
  gap: 24px;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(24, 26, 30, 0.16), 0px 32px 64px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  justify-content: space-between;
  position: sticky;
  bottom: 20px;
  z-index: 1000;
`;

export const ButtonSave = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  :hover {
    background: #01cfb5;
  }
`;

export const InputStyle = styled.div`
  max-width: 544px;
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 48px;
  border: 3px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  :hover {
    box-shadow: 0px 0px 0px 6px rgba(61, 85, 130, 0.2);
    background-color: #fff;
    border: 3px solid #3d5582;
  }
  :focus-within {
    box-shadow: 0px 0px 0px 6px rgba(1, 207, 181, 0.2);
    background-color: #fff;
    border: 3px solid #01cfb5;
  }
  input {
    width: 100%;
    height: 30px;
    border: none;
    background: none;
    color: #3d5582;
    font-size: 16px;

    ::placeholder {
      color: #3d5582;
      font-weight: 400;
    }
    ::-webkit-inner-spin-button {
      color: red;
    }
  }
`;

export const ButtonBack = styled(ButtonSave)`
  background: unset;
  color: #01c3aa;
  :hover {
    background: #e5faf7;
  }
`;

export const NoQuestionsDiv = styled.div`
  display: flex;
  padding: 64px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
`;

export const CheckBoxStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  input {
    opacity: 0;
    position: absolute;
  }

  input + label {
    position: relative;
    padding-left: 35px;
    height: 15px;
    color: #767676;
    font-size: 14px;
    letter-spacing: 0.5px;
    line-height: 15px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      width: 21px;
      height: 21px;
      border: 2px solid #eee;
      background: #fff;
      border-radius: 5px;
    }
    &:hover::before {
      box-shadow: 0px 0px 0px 4px rgba(61, 85, 130, 0.2);
      border: 2px solid #3d5582;
    }
    &:hover::after {
      box-shadow: 0px 0px 0px 4px rgba(1, 207, 181, 0.2);
      border: 2px solid #01cfb5;
    }
    &:after {
      content: '✔';
      position: absolute;
      bottom: 0px;
      left: 0;
      padding-top: 2px;
      padding-left: 2px;
      width: 21px;
      height: 21px;
      font-size: 16px;
      color: #01cfb5;
      border: 2px solid #d1d7e1;
      border-radius: 5px;
      background: #ffffff;
      transition: all 0.2s;
    }

    label {
      padding-top: 5px;
      font-size: 16px;
      letter-spacing: 0.62px;
      line-height: 19px;
    }
  }

  input:not(:checked) + label {
    &:after {
      opacity: 0;
      transform: scale(0);
    }
  }
  input:disabled:not(:checked) + label {
    &:before {
      box-shadow: none;
      border-color: #000;
      background-color: #ddd;
    }
  }

  input:checked + label {
    color: #00205b;
    &:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  input:disabled:checked + label {
    &:after {
      color: #fff;
      background-color: #bbb;
      border: 1px solid #bbb;
    }
  }
  input:disabled + label {
    color: #343434;
  }
`;

export const ImportButton = styled.div`
  display: flex;
  cursor: pointer;
  height: 48px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 320px;
  border: 2px solid #e5e8ee;
  color: #019d8a;
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px;
  :hover {
    border: 2px solid #d1d7e1;
  }
`;

export const SearchButton = styled.button`
  margin-left: auto;
  cursor: pointer;
  display: flex;
  height: 48px;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #01b69f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
  border: none;
  background: #fff;
  :hover {
    background: #e5faf7;
    border-radius: 320px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  color: #00205b;
  font-size: 22.8px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.4px;
  margin: 20px 0 16px;
`;

export const QuestionButton = styled.button`
  display: flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  border-radius: 1000px;
  border: none;
  background: #fff;
  color: var(--secondary-100, #01c3aa);
  text-align: center;
  font-size: 14.2px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.958px;
  :hover {
    background: #e5faf7;
  }
`;

export const QuestionButtonsContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 8px;
  border: 2px solid var(--primary-020, #e5e8ee);
  background: var(--main-white, #fff);
`;

export const QuestionContent = styled.div`
  color: var(--primary-accent, #00205b);
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 24px;
  line-height: 24px; /* 133.333% */
  whitespace: nowrap;
  align-self: stretch;
`;

export const QuestionHeader = styled.div`
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  width: 100%;
  cursor: default;
`;

export const QuestionInfoContent = styled.div`
  display: flex;
  gap: 24px;
`;

export const QuestionNumber = styled.div`
  color: var(--primary-accent, #00205b);
  gap: 30px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 133.333% */
`;

export const GrayCircle = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1000px;
  background: radial-gradient(50% 50% at 50% 50%, rgba(229, 232, 238, 0) 0%, #e5e8ee 100%);
  margin-bottom: 24px;
`;

export const ReviewText = styled.span`
  cursor: default;
  margin-top: 10px;
  color: var(--main-090, #2a2c30);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21.3px; /* 133.125% */
  align-self: stretch;
`;

export const ReviewWarning = styled.div`
  display: flex;
  padding: 16px 24px;
  margin: 0px 32px;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background: ${(props) => (props.warning ? '#DAE6F4' : '#DAE6F4')};
`;

export const ReviewWarningLogo = styled.div`
  display: flex;
  width: var(--3-x, 48px);
  height: var(--3-x, 48px);
  justify-content: center;
  align-items: center;
  border-radius: 1000px;
  background: ${(props) => (props.warning ? '#1862BC' : '#1862BC')};
`;

export const ReviewMainText = styled.span`
  align-self: stretch;
  color: var(--text-title, #181a1e);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21.3px; /* 133.125% */
`;

export const ReviewSubText = styled.span`
  align-self: stretch;
  color: var(--text-paragraph, #4f5154);
  font-size: 14.2px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.958px; /* 133.507% */
`;

export const ReviewTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: var(--025-x, 4px);
  flex: 1 0 0;
`;

export const RadioContainer = styled.div`
  display: flex;
  gap: 24px;
  align-self: stretch;
`;

export const SelectContainer = styled.div`
  display: flex;
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: 544px;

  > div {
    width: 100%;
  }
`;

export const Button = styled.button`
  display: flex;
  border-radius: 320px;
  background: #0caa87;
  height: 40px;
  width: 200px;
  padding: 10px 16px;

  justify-content: center;
  align-items: center;
  color: #fff;

  font-size: 14px;
  font-weight: 600;
  border: none;
  gap: 10px;

  :hover {
    background: #01cfb5;
  }

  :disabled {
    color: #8f9db7;
    background: #019d8a;
    cursor: default;
  }
`;

export const SelectedResponsibleContainer = styled.div`
  margin-top: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 10px 0;
`;

export const SelectedResponsibleText = styled.p`
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: #2a2c30;
  display: flex;
  align-items: center;
`;

export const SelectedResponsibleMultiplierText = styled(SelectedResponsibleText)`
  color: #00205b;
`;

export const SelectedResponsibleMultiplierDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 22px;
`;
