import { useQuery } from '@tanstack/react-query';

import { QuestionAPICMS } from '~/services/apiCallsCMS';

const fetchQuestionById = async (id) => {
  try {
    const response = await QuestionAPICMS.get(id);
    if (!response && !response.data) {
      throw new Error('No results found');
    }

    return response.data;
  } catch (error) {
    throw error;
  }
};

export default function useQuestionDetail(id, options) {
  return useQuery({
    queryKey: ['questionDetail', id],
    queryFn: () => fetchQuestionById(id),
    staleTime: 10 * 60000,
    retryDelay: 1,
    retry: 0,
    ...options?.config,
  });
}
