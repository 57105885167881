import styled, { css } from 'styled-components';

export const HeaderContainer = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ListExams = styled.div`
  position: relative;
  width: 100%;
`;

export const MainContainer = styled.div`
  padding-right: 5px;
  width: 100%;
  min-height: 100%;
  background-color: #f5f5fb;
`;

export const Filter = styled.div`
  max-width: 604px;
  width: 100%;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  background: #f0f2f5;
  padding: 7px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  min-height: 32px;

  &:focus-within {
    background-color: #ffffff;
    box-shadow: 0 3px 8px 0 rgba(0, 32, 91, 0.15);
    color: #00205b;
    letter-spacing: 0.5px;
    line-height: 15px;
  }

  input {
    width: 100%;
    border: none;
    background: none;

    &::placeholder {
      font-size: 14.2px;
      line-height: 18.96px;
      color: #3d5582;
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-wrap: wrap;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  padding: 20px 40px;
`;

export const FiltesTitle = styled.h3`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
  margin: 0;
`;

export const FilterComponents = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
`;

export const NotFound = styled.div`
  margin-left: 5px;
  margin-top: 20px;
  height: 19px;
  color: #3b3fb6;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  padding: 48px;
  max-width: 1388px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const ButtonsContainer = styled.div`
  flex-basis: 7%;
`;

export const ButtonFilter = styled.button`
  padding: 10px 16px;
  background: #01c3aa;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #01cfb5;
  }
`;

export const ClearButton = styled.button`
  color: #00205b;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 18.96px;
  padding: 10px 16px;
  border: 2px solid #e5e8ee;
  border-radius: 1000px;
  background-color: #fff;

  :hover {
    border-color: #d1d7e1;
  }
`;

export const CardBook = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
`;

export const HeaderCardBook = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
`;

export const InfoBook = styled.div`
  flex-direction: column;
  gap: 4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const QuestionsContainer = styled.div`
  color: #3d5582;
  font-size: 14.2px;
`;

export const TitleChapter = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
  max-width: 45vw;
`;

export const QuestionsTag = styled.span`
  border: 1px solid #00205b;
  border-radius: 1000px;
  padding: 4px 8px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const CouserName = styled(QuestionsTag)`
  background-color: #e5e8ee;
  border: 1px solid #e5e8ee;
`;

export const CouserNameClickable = styled(CouserName)`
  cursor: pointer;
`;

export const CoursesList = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-bottom: 46px;
  max-height: 156px;
  overflow: hidden scroll;
`;

export const PrePosTag = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #01c3aa;
  background-color: #e5faf7;
  font-weight: 600;
  font-size: 12.6px;
  line-height: 16.9px;
  padding: 0 8px;
  border-radius: 1000px;
  height: 24px;
`;

export const Controls = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonGenerateSlide = styled.div`
  color: #00205b;
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  border: 2px solid #e5e8ee;
  cursor: pointer;
`;

export const ButtonEdit = styled.div`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #01c3aa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #e5faf7;
  }
`;

export const FooterCardBook = styled.div`
  border-top: 1px solid #e5e8ee;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 8px 24px;
  flex-wrap: wrap;
  min-height: 58px;
`;

export const ContainerPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 400;
    font-size: 12.6px;
    line-height: 17px;
    color: #001e56;
  }
`;

export const Pagination = styled.div`
  display: flex;
`;

export const ButtonPagination = styled.button`
  height: 40px;
  display: flex;
  gap: 10px;
  padding: 10px 16px;
  background: none;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #01c3aa;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  :hover {
    background: #e5faf7;
  }

  :disabled {
    color: #d1d7e1;
    cursor: default;
    background: none;

    svg {
      path {
        fill: #d1d7e1;
      }
    }
  }
`;

export const Tabs = styled.div`
  display: flex;
  background: #fff;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  border-radius: 8px;
  height: 64px;
  padding: 0 40px;
`;

export const TabButton = styled.div`
  display: flex;
  gap: 12px;
  flex: 0 0 50%;
  background: transparent;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 21.3px;
  color: ${(props) => (props.active ? '#01CFB5' : '#00205B')};
  border-bottom: ${(props) => (props.active ? '2px solid #01CFB5' : 'none')};
`;

export const TabTag = styled.div`
  display: flex;
  background: #01cfb5;
  color: #fff;
  font-size: 12.6px;
  line-height: 16.9px;
  border-radius: 1000px;
  padding: 0 8px;
  min-height: 24px;
  justify-content: center;
  align-items: center;
`;

export const EmptyContentIconContaner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #e5e8ee;
`;

export const EmptyContentIcon = styled.img`
  width: 22.14px;
  height: 22.14px;
`;

export const EmptyContentContainer = styled.div`
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EmptyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const EmptyContentText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4x;
`;

export const EmptyContentTitle = styled.h3`
  font-weight: 600;
  font-size: 20.3px;
  line-height: 27px;
  color: #00205b;
  margin: 0;
`;

export const EmptyContentSubtitle = styled.p`
  font-size: 16px;
  line-height: 21.3px;
  color: #3d5582;
  margin: 0;
`;

export const FilterBox = styled.div`
  flex-basis: ${(props) => props.flexBasis};
  flex-grow: 2;
`;

export const CardProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const ChipCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 26px;
  background: #fcdbdf;
  color: #ee1f38;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  ${(props) =>
    props.complete &&
    css`
      background: #d8f4e6;
      color: #0cba66;
    `}
`;

export const ViewCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
`;

export const ItemSubtitle = styled.span`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 19px;
  color: #3d5582;

  strong {
    font-weight: 600;
  }
`;

export const RowCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const TooltipDiv = styled.div`
  display: flex;
  align-items: center;
`;
