import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { updateTrack } from '~/store/modules/track/actions';
import { Question } from '~/services';
import DropsCircleCheck from '~/assets/dropsCircleCheck';
import ErrorIcon from '~/assets/drops-error-outline.svg';
import SettingsIcon from '~/assets/drops-settings.svg';
import PlayIcon from '~/assets/drops-play.svg';
import { Mixpanel } from '~/services/analytics';
import Loader from '../Loader';
import * as S from './styles';

export default function QuestionItem({
  action,
  itemHeight,
  itemWidth,
  question,
  check,
  onPressItem,
  colorClass,
  customStyle,
  ...rest
}) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [correctOptionsMix, setCorrectOptionsMix] = useState([]);
  const [correctOptions, setCorrectOptions] = useState([]);
  const [questionSelected, setQuestionSelected] = useState();
  const [loadOption, setLoadOption] = useState({
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
  });
  const {
    track: { track },
  } = useSelector((state) => state.track);
  useEffect(() => {
    if (question.correct_answer) {
      setCorrectOptions(question?.correct_answer.map((item) => item.id));
      setCorrectOptionsMix(question?.correct_answer.map((item) => item.letter));
    }
  }, [question]);

  const getListTrack = useCallback(() => {
    dispatch(updateTrack(id));
  }, [dispatch, id]);

  const handleOption = (optionId) => {
    setQuestionSelected(question.id);
    const letterSelected = question?.options.filter((option) => option.id === optionId)[0].letter;
    setLoadOption({ ...loadOption, [letterSelected]: true });
    const correctAnswer = correctOptions;
    let correctLetter = correctOptionsMix;
    const index = correctAnswer.indexOf(optionId);
    if (index === -1) {
      correctAnswer.push(optionId);
    } else {
      correctAnswer.splice(index, 1);
    }
    const indexLetter = correctLetter.indexOf(letterSelected);
    if (indexLetter === -1) {
      correctLetter.push(letterSelected);
    } else {
      correctLetter.splice(index, 1);
    }
    correctLetter = correctLetter.sort();
    const data = {
      correct_options: correctAnswer,
    };
    Question.update(question.id, data)
      .then(() => {
        getListTrack();
        toast.success(`Gabarito da questão ${question.question_number} atualizado com sucesso!`);
        Mixpanel.track("Clicou 'Tela da prova - alternativas'", {
          'Nome da prova': track.name,
          Questão: question.id,
          Especialidade: track.specialities,
          'Número da questão': question.question_number,
          Opções: question.options.map((option) => `${option.letter}`),
          'Alternativas corretas': correctLetter.map((letter) => `Alternativa ${letter}`),
        });
      })
      .catch(() => {
        toast.error(`Erro ao salvar o Gabarito da questão ${question.question_number}!`);
      })
      .finally(() => setLoadOption({ ...loadOption, [letterSelected]: false }));
  };

  const CommentTitle = () => {
    if (check && !!question?.content) {
      return (
        <S.PreviewContent>{`${
          question?.options?.length !== 0 && action === 'text'
            ? question?.content.substr(0, 55)
            : question?.content.substr(0, 80)
        }...`}</S.PreviewContent>
      );
    }
    if (check && !question?.content) {
      return <S.NoContent> Sem enunciado </S.NoContent>;
    }
    if (!check) {
      return <S.NoContent> Sem comentário </S.NoContent>;
    }
  };

  return (
    <S.Container
      colorSpeciality={colorClass}
      style={{
        width: itemWidth,
        height: itemHeight,
        ...customStyle,
      }}
      {...rest}
    >
      <S.RowCard>
        {check ? <DropsCircleCheck /> : <img src={ErrorIcon} alt="Icon Error" />}
        <S.QuestionNumber>{question?.question_number}</S.QuestionNumber>
        {action === 'tags' && (
          <>
            {question?.tag_name ? (
              <S.ChipTag className="chip-tag">{question?.tag_name}</S.ChipTag>
            ) : (
              <S.NoContent>Sem tag</S.NoContent>
            )}
          </>
        )}
        {(action === 'text' || action === 'video') && (
          <>
            {question?.has_content || !!question?.content ? (
              <S.PreviewContent>{`${
                question?.options?.length !== 0 && action === 'text'
                  ? question?.content.substr(0, 55)
                  : question?.content.substr(0, 80)
              }...`}</S.PreviewContent>
            ) : (
              <S.NoContent>Sem enunciado</S.NoContent>
            )}
          </>
        )}
        {action === 'comment' && <CommentTitle />}
      </S.RowCard>
      <S.RowCard>
        {action === 'text' && question?.options?.length !== 0 && (
          <S.ContainerOptions>
            {question?.canceled ? (
              <S.CanceledQuestion>Questão Anulada</S.CanceledQuestion>
            ) : (
              <>
                {question?.options.map((option) => {
                  return (
                    <S.Option
                      key={option.id}
                      onClick={() => handleOption(option.id)}
                      correctOption={correctOptions.includes(option.id)}
                    >
                      {loadOption[option.letter] && questionSelected === question.id ? (
                        <Loader spinner="ring" text="" customStyle={{ scale: '0.5' }} />
                      ) : (
                        <>
                          {option.letter} {correctOptions.includes(option.id) && <S.Check />}
                        </>
                      )}
                    </S.Option>
                  );
                })}
              </>
            )}
          </S.ContainerOptions>
        )}
        {action === 'comment' && question?.has_comment_video && (
          <S.ChipVideo className="chip-video">
            Vídeo
            <img src={PlayIcon} alt="play icon" />
          </S.ChipVideo>
        )}
        <S.EditButton onClick={onPressItem}>
          <img src={SettingsIcon} alt="settings icon" />
          Editar
        </S.EditButton>
      </S.RowCard>
    </S.Container>
  );
}

QuestionItem.propTypes = {
  check: PropTypes.bool,
  numberQuestion: PropTypes.number,
  itemHeight: PropTypes.number,
  itemWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onPressItem: PropTypes.func,
  customStyle: PropTypes.shape(),
};

QuestionItem.defaultProps = {
  check: false,
  numberQuestion: 1,
  itemHeight: 60,
  itemWidth: 135,
  onPressItem: () => {},
  customStyle: {},
};
