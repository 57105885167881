import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between title and button */
  padding: 40px 0 20px 0;
`;

export const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-weight: 600;
  font-size: 18px;
  color: #00205b;
`;
