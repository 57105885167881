import React, { useState, useEffect } from 'react';

import { GoEye } from 'react-icons/go';
import { MdOutlineSave } from 'react-icons/md';
import { ReactComponent as FlagIcon } from '~/assets/drops-flag.svg';
import Loader from '~/components/Loader';
import Tags from '~/components/Tags';
import Checkbox from '~/components/Checkbox';
import EmptyContent from '~/components/EmptyContent';

import IncludedTracks from './IncludedTracks';
import QuestionDetail from './QuestionDetail';
import Pagination from '~/components/Pagination';

import { checkIfArrayContainsAnother, getSpecialityColor } from '~/utils/util';
import { useQuestionDetail } from '~/hooks-querys/question';
import { useSearchQuestionsModalListTracks } from '~/hooks-querys/searchQuestionsModal';

import { Mixpanel } from '~/services/analytics';
import { Title } from '../styles';
import * as S from './styles';

const difficultyMap = {
  e: 'Fácil',
  m: 'Médio',
  h: 'Difícil',
};

const questionTypeMap = {
  d: 'Discursiva',
  o: 'Objetiva',
};

function Questions({
  data,
  loadingData,
  errorData,
  pagination,
  currentPage,
  setCurrentPage,
  totalPages,
  allCheckedQuestions,
  setAllCheckedQuestions,
  save,
  totalResults,
}) {
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [showListTracks, setShowListTracks] = useState(false);
  const [showQuestionDetail, setShowQuestionDetail] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const { data: listTracksData, isLoading: loadingListTracks } = useSearchQuestionsModalListTracks(
    { id: selectedQuestion },
    {
      config: {
        enabled: showListTracks && !!selectedQuestion,
      },
    },
  );

  const { data: questionDetail, isLoading: loadingQuestionDetail } = useQuestionDetail(
    selectedQuestion,
    {
      config: {
        enabled: showQuestionDetail && !!selectedQuestion,
      },
    },
  );

  const extractIdFromData = () => {
    return data.map((item) => item.id);
  };

  const goToPreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleCheck = (id) => {
    if (checkedQuestions.includes(id)) {
      const checkedQuestionsWithRemovedId = checkedQuestions.filter((item) => item !== id);
      const allCheckedQuestionsWithRemovedId = allCheckedQuestions.filter((item) => item !== id);

      setCheckedQuestions(checkedQuestionsWithRemovedId);
      setAllCheckedQuestions(allCheckedQuestionsWithRemovedId);
      return;
    }

    setCheckedQuestions((checkedQuestions) => [...checkedQuestions, id]);
    setAllCheckedQuestions((allCheckedQuestions) => [...allCheckedQuestions, id]);
    return;
  };

  const selectAll = () => {
    Mixpanel.track("Clicou 'Busca de questões - selecionar tudo'", {});
    const currentPageSelectedQuestions = data.filter((item) => {
      return checkedQuestions.includes(item.id);
    });

    if (
      currentPageSelectedQuestions.length &&
      currentPageSelectedQuestions.length === data.length
    ) {
      const newAllChecked = allCheckedQuestions.filter((id) => {
        return !extractIdFromData().includes(id);
      });
      setAllCheckedQuestions(newAllChecked);
      setIsAllChecked(false);
      return setCheckedQuestions([]);
    }

    let newCheckedQuestions = [];
    data.map((item) => {
      newCheckedQuestions.push(item.id);
    });

    setIsAllChecked(true);
    setAllCheckedQuestions((allCheckedQuestions) => [
      ...allCheckedQuestions,
      ...newCheckedQuestions,
    ]);
    return setCheckedQuestions(newCheckedQuestions);
  };

  const handleIncludedTracks = (id) => {
    Mixpanel.track("Clicou 'Busca de questões - trilhas inclusas'", {});
    setShowListTracks(true);
    setSelectedQuestion(id);
    if (showQuestionDetail) {
      setShowQuestionDetail(false);
    }
  };

  const handleViewQuestion = (id) => {
    Mixpanel.track("Clicou 'Busca de questões - visualização questão'", {});
    setShowQuestionDetail(true);
    setSelectedQuestion(id);
    if (showListTracks) {
      setShowListTracks(false);
    }
  };

  const handleSave = () => {
    Mixpanel.track("Clicou 'Busca de questões - salvar e fechar modal'", {});
    save();
  };

  const handleCloseQuestionDetail = () => {
    setSelectedQuestion(null);
    setShowQuestionDetail(false);
  };

  const handleCloseListTracks = () => {
    setSelectedQuestion(null);
    setShowListTracks(false);
  };

  const returnCurrentIndexOfSelectedQuestion = (idList, currentId) => {
    const isId = (id) => id === currentId;
    return idList.findIndex(isId);
  };

  const handleBack = (currentId) => {
    const idList = extractIdFromData();
    const currentIndex = returnCurrentIndexOfSelectedQuestion(idList, currentId);
    if (currentIndex === 0) return;
    setSelectedQuestion(idList[currentIndex - 1]);
  };

  const handleNext = (currentId) => {
    const idList = extractIdFromData();
    const currentIndex = returnCurrentIndexOfSelectedQuestion(idList, currentId);
    if (currentIndex >= 9) return;
    setSelectedQuestion(idList[currentIndex + 1]);
  };

  const disableBackButton = (currentId) => {
    const idList = extractIdFromData();
    const currentIndex = returnCurrentIndexOfSelectedQuestion(idList, currentId);
    return currentIndex === 0;
  };

  const disableNextButton = (currentId) => {
    const idList = extractIdFromData();
    const currentIndex = returnCurrentIndexOfSelectedQuestion(idList, currentId);
    return currentIndex >= 9;
  };

  const getTags = (item) => {
    return [
      {
        textColor: '#fff',
        backgroundColor: getSpecialityColor(item.specialities[0]?.name),
        text: item.specialities[0]?.name,
      },
      {
        textColor: '#fff',
        backgroundColor: '#00205B',
        text: item.residency_degree,
        isUppercase: true,
      },
      {
        type: 'secondary',
        textColor: '#00205B',
        text: questionTypeMap[item.question_type],
      },
      {
        textColor: '#00205B',
        backgroundColor: '#E5E8EE',
        text: `${item.correct_answer_avg}% de acertos`,
      },
      {
        textColor: '#01C3AA',
        backgroundColor: '#E5FAF7',
        text: difficultyMap[item.difficulty],
      },
    ];
  };

  useEffect(() => {
    if (checkIfArrayContainsAnother(allCheckedQuestions, extractIdFromData())) {
      setCheckedQuestions(extractIdFromData());
      return setIsAllChecked(true);
    }
    return setIsAllChecked(false);
  }, [data]);

  const RenderItem = (item) => {
    const fullContent = item.institution_name
      ? `[${item.institution_name} - ${item.year}] ${item.content || 'Questão sem Enunciado'}`
      : `[${item.year}] ${item.content || 'Questão sem Enunciado'}`;

    return (
      <S.ItemContainer selected={selectedQuestion === item.id}>
        <S.ItemHeader>
          <Checkbox
            id={item.id}
            label=""
            checked={allCheckedQuestions.includes(item.id)}
            onChange={() => handleCheck(item.id)}
            value={item.id}
            styleContainer={{ margin: '5px 0 0', width: '21px' }}
            styleLabel={{ marginBottom: 0 }}
            checkboxOutineColor="#ccc"
          />
          <S.ItemLabel onClick={() => handleCheck(item.id)}>
            {fullContent || 'Questão sem enunciado'}
          </S.ItemLabel>
          <S.IncludedTracksButton onClick={() => handleIncludedTracks(item.id)}>
            <S.FlagContainer>
              <FlagIcon />
            </S.FlagContainer>
            {showListTracks || showQuestionDetail ? '' : 'Trilhas inclusas'}
          </S.IncludedTracksButton>
          <S.SeeQuestionButton onClick={() => handleViewQuestion(item.id)}>
            <GoEye />
            {showListTracks || showQuestionDetail ? '' : 'Visualizar questão'}
          </S.SeeQuestionButton>
        </S.ItemHeader>
        <S.ItemFooter>
          {getTags(item).map((tag) => {
            if (!tag.text) return <></>;
            return (
              <Tags
                type={tag.type}
                textColor={tag.textColor}
                backgroundColor={tag.backgroundColor}
                text={tag.text}
                textUppercase={tag.isUppercase}
              />
            );
          })}
        </S.ItemFooter>
      </S.ItemContainer>
    );
  };
  return (
    <S.Container>
      <S.Questions showList={showListTracks || showQuestionDetail}>
        <S.Header>
          <Title>{`Mostrando ${data?.length || 0} resultados`}</Title>
        </S.Header>

        <S.Content>
          {loadingData && !errorData ? (
            <Loader />
          ) : (
            <>
              {data?.length > 0 ? (
                <>
                  {data?.map((item) => RenderItem(item))}
                  <S.EndContent>
                    {pagination && (
                      <S.PaginationContainer smallScreen={showListTracks || showQuestionDetail}>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          backPage={goToPreviousPage}
                          nextPage={goToNextPage}
                          setPage={setCurrentPage}
                        />
                      </S.PaginationContainer>
                    )}
                    <S.TotalQuestions smallScreen={showListTracks || showQuestionDetail}>
                      <b>{totalResults}</b>
                      {totalResults === 1 ? ' questão' : ' questões'}
                    </S.TotalQuestions>
                  </S.EndContent>
                </>
              ) : (
                <>
                  <EmptyContent
                    title="Nenhuma questão encontrada"
                    subtitle="Altere os critérios de busca e tente novamente "
                  />
                </>
              )}
            </>
          )}
        </S.Content>

        <S.Footer>
          <Checkbox
            id="select-all"
            label="Selecionar tudo"
            checked={isAllChecked}
            onChange={selectAll}
            value="select-all"
            styleContainer={{
              margin: '5px 0 0',
              flexShrink: 0,
            }}
            styleLabel={{
              marginBottom: 0,
              fontSize: '16px',
              marginBottom: '5px',
              color: '#00205B',
            }}
            checkboxOutineColor="#ccc"
          />
          <S.RightItemsFooter>
            <S.SelectedItems>
              <Tags textColor="#00205B" backgroundColor="#E5E8EE" text={checkedQuestions.length} />
              {checkedQuestions.length === 1 ? 'Selecionada' : 'Selecionadas'}
            </S.SelectedItems>
            <S.SaveButton onClick={handleSave} disabled={allCheckedQuestions.length <= 0}>
              <MdOutlineSave color={allCheckedQuestions <= 0 ? '#8F9DB7' : '#fff'} size={20} />
              {showListTracks || showQuestionDetail ? '' : 'Salvar e fechar'}
            </S.SaveButton>
          </S.RightItemsFooter>
        </S.Footer>
      </S.Questions>

      {showListTracks && (
        <S.ListTracks>
          <IncludedTracks
            trackList={listTracksData}
            loading={loadingListTracks}
            close={handleCloseListTracks}
          />
        </S.ListTracks>
      )}

      {showQuestionDetail && (
        <S.ListTracks>
          <QuestionDetail
            question={questionDetail}
            loading={loadingQuestionDetail}
            close={handleCloseQuestionDetail}
            goBack={handleBack}
            goNext={handleNext}
            disableBackButton={disableBackButton}
            disableNextButton={disableNextButton}
          />
        </S.ListTracks>
      )}
    </S.Container>
  );
}

export default Questions;
