import React from 'react';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { BiCheckCircle } from 'react-icons/bi';
import { BiTime } from 'react-icons/bi';
import { GrStatusWarning } from 'react-icons/gr';

export const FILTERS_INITIAL_STATE = {
  selectedPartner: null,
  selectedMonth: { value: '', label: '' },
  selectedYear: { value: '', label: '' },
  selectedStatus: { value: '', label: '' },
  selectedResidencyDegree: { value: '', label: '' },
};

export const STATUS_OPTIONS = [
  {
    label: 'Verificando',
    value: 'c',
    color: '#001C50',
    bgColor: '#E5E8EE',
    icon: <BiTime size={16} color={'#001C50'} />,
  },
  {
    label: 'Disponível',
    value: 'a',
    color: '#098D4E',
    bgColor: '#D8F4E6',
    icon: <IoCheckmarkDoneSharp size={16} color={'#098D4E'} />,
  },
  {
    label: 'Processando',
    value: 'b',
    color: '#124A8F',
    bgColor: '#DAE6F4',
    icon: <BiTime size={16} color={'#124A8F'} />,
  },
  {
    label: 'Em análise',
    value: 'f',
    color: '#001C50',
    bgColor: '#E5E8EE',
    icon: <BiTime size={16} color={'#001C50'} />,
  },
  {
    label: 'Pago',
    value: 'd',
    color: '#098D4E',
    bgColor: '#D8F4E6',
    icon: <BiCheckCircle size={16} color={'#098D4E'} />,
  },
  {
    label: 'Reportado',
    value: 'r',
    color: '#B99503',
    bgColor: '#FDF6D7',
    icon: <GrStatusWarning size={16} color={'#B99503'} />,
  },
  {
    label: 'Erro na ordem',
    value: 'e',
    color: '#B5182B',
    bgColor: '#FCDBDF',
    icon: <GrStatusWarning size={16} color={'#B5182B'} />,
  },
];

export const AGGREGATION = [
  {
    label: 'Mês',
    key: 'month',
  },
  {
    label: 'Ano',
    key: 'year',
  },
];

export const ORDERING = [
  {
    label: 'A-Z',
    key: 'name',
  },
  {
    label: 'Z-A',
    key: '-name',
  },
  {
    label: 'Tipo de atividade',
    key: 'activity',
  },
];

export const MULTIPLIER = [
  {
    value: '1.0',
    label: '1x',
  },
  {
    value: '1.1',
    label: '1.1x',
  },
  {
    value: '1.2',
    label: '1.2x',
  },
  {
    value: '1.3',
    label: '1.3x',
  },
  {
    value: '1.4',
    label: '1.4x',
  },
];

export const QUALITY = [
  {
    value: 80,
    label: '80%',
  },
  {
    value: 90,
    label: '90%',
  },
  {
    value: 100,
    label: '100%',
  },
];

export const ACTIONS_LIST = [
  { label: 'Excluir', key: 'delete', value: 'delete' },
  { label: 'Alterar multiplicador', key: 'change_multiplier', value: 'change_multiplier' },
  { label: 'Alterar status', key: 'change_status', value: 'change_status' },
  { label: 'Alterar qualidade', key: 'change_quality', value: 'change_quality' },
  { label: 'Desconsiderar multa', key: 'ignore_penalty', value: 'ignore_penalty' },
  { label: 'Atualizar valor da atividade', key: 'price_override', value: 'price_override' },
];

export const PARTNER_FORMS_LINK =
  'https://docs.google.com/forms/d/e/1FAIpQLSc4eYrTJ6nkI03xBaEAF28Ko51I5z9NO46L7VfoT5NsypYxJQ/viewform';
