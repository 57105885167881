import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from 'axios';

import { TrackAPICMS } from '~/services/apiCallsCMS';
import history from '~/services/history';
import { Mixpanel } from '~/services/analytics';
import HeaderComponent from '~/components/HeaderComponent';
import ModalScreen from '~/components/ModalScreen';
import ProgressBar from '~/components/ProgressBar';
import Button from '~/components/ButtonComponent';
import Loader from '~/components/Loader';
import Tabs from '~/components/Tabs';

import { useDispatch, useSelector } from 'react-redux';
import { requestTrack } from '~/store/modules/track/actions';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import QuestionsTab from './QuestionsTab';
import * as S from './styles';

export default function ListQuestions() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [actions, setActions] = useState([]);
  const [hasPendency, setHasPendency] = useState(true);

  const {
    track: { track, requesting },
  } = useSelector((state) => state.track);

  const getListTrack = useCallback(() => {
    dispatch(requestTrack(id));
  }, [dispatch, id]);

  function hasPendencies(item) {
    if (item.questions_count > item.questions_with_tags) return true;
    if (item.questions_count > item.questions_with_content) return true;
    if (item.question_objective_count > item.questions_with_correct_answer) return true;
    return false;
  }

  useEffect(() => {
    if (requesting) return;

    const source = Axios.CancelToken.source();
    if (!id) {
      history.push('/');
    } else {
      getListTrack();
    }

    return () => {
      source.cancel();
    };
  }, [id]);

  const publishExam = () => {
    Mixpanel.track("Clicou 'Tela de prova - Publicar prova'", {
      'Nome da prova': track.name,
      'Categoria da prova':
        track.track_type === 'c' ? 'Exame' : track.track_type === 'b' && 'Simulado',
      'Tipo de prova': track.question_objective_count > 0 ? 'Objetiva' : 'Discursiva',
      Periodo: track.exam_period,
      Jornada: track.track_category,
      Ano: track.year,
      Instituição: track.institution,
    });
    setOpenPublishModal(true);
  };

  useEffect(() => {
    const actionsKey = [
      'questions_with_content',
      'questions_with_tags',
      'question_with_comments',
      'questions_with_correct_answer',
      'questions_with_video',
    ];
    const actionsLabel = ['Enunciadas', 'Tagueadas', 'Comentadas', 'Gabarito', 'Videos'];
    const actionsClone = [];
    for (const key in track) {
      if (actionsKey.includes(key)) {
        actionsClone.push({
          label: actionsLabel[actionsKey.indexOf(key)],
          value: track[key],
          percentage:
            track?.questions_count === 0
              ? 0
              : Math.floor((100 * track[key]) / track?.questions_count),
        });
        setActions(actionsClone);
      }
    }
    setHasPendency(hasPendencies(track));
  }, [track]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setEnabledTrack = (enabled, trackId) => {
    const source = Axios.CancelToken.source();
    TrackAPICMS.update(trackId, { enabled }, source.token)
      .then(() => {
        toast.success('Publicado com sucesso');
      })
      .catch(() => {
        toast.error('Não foi possível publicar');
      })
      .finally(() => {
        setOpenPublishModal(false);
      });
  };

  return (
    <>
      {requesting ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent
            image={track?.thumbnail ?? null}
            title={track?.name ?? ''}
            description={`${track?.questions_count ?? ''} questões`}
            cms={track?.id}
            permissions={['add_track']}
            limitTitle={track && !requesting && actions.length > 0}
          >
            {track && !requesting && (
              <S.ProgressContainer small={actions.length <= 4}>
                {actions.map((item, index) => {
                  const complete = item.percentage === 100;
                  return (
                    <S.CardProgress key={index}>
                      <S.ChipCount complete={complete}>{item.value}</S.ChipCount>
                      <S.ViewCol style={{ gap: 6 }}>
                        <S.ItemSubtitle>
                          {item.label} <strong>{item.percentage}%</strong>
                        </S.ItemSubtitle>
                        <ProgressBar
                          unfilledColor={complete ? '#D8F4E6' : '#FCDBDF'}
                          color={complete ? '#0CBA66' : '#EE1F38'}
                          percentage={item.percentage}
                          height={4}
                          style={{ minWidth: '140px' }}
                        />
                      </S.ViewCol>
                    </S.CardProgress>
                  );
                })}
              </S.ProgressContainer>
            )}
          </HeaderComponent>

          <S.Wrap>
            <S.Container>
              <S.MainContainer>
                <Tabs>
                  <div label="Enunciar">
                    <QuestionsTab track={track} action="text" />
                  </div>
                  <div label="Taguear">
                    <QuestionsTab track={track} action="tags" />
                  </div>
                  <div label="Comentar">
                    <QuestionsTab track={track} action="comment" />
                  </div>
                  <div label="Videos">
                    <QuestionsTab track={track} action="video" />
                  </div>
                </Tabs>
              </S.MainContainer>
            </S.Container>
          </S.Wrap>

          <S.ContainerFooter>
            <S.FooterTrack>
              <S.ButtonBack type="button" onClick={() => history.push('/exam')}>
                <MdArrowBack size={18} color="#01c3aa" />
                Voltar
              </S.ButtonBack>
              <S.ButtonSave type="submit" onClick={() => publishExam()}>
                Publicar
                <MdArrowForward size={18} />
              </S.ButtonSave>
            </S.FooterTrack>
          </S.ContainerFooter>

          <ModalScreen show={openPublishModal}>
            <S.ContainerModal>
              {hasPendency ? (
                <h3>
                  Há pendências nesta prova (Enunciado, Gabarito, Tags ou Comentários). Por favor,
                  complete-as antes de publicar.
                </h3>
              ) : (
                <h3>Você tem certeza que quer publicar?</h3>
              )}
              <S.GroupButtonModal>
                <Button
                  type="important"
                  text={hasPendency ? 'OK' : 'Não'}
                  styleContainer={{ marginLeft: 18 }}
                  primaryColor="#01cfb5"
                  textColor="#fff"
                  onClick={() => setOpenPublishModal(false)}
                />
                {!hasPendency && (
                  <Button
                    type="important"
                    text="Sim"
                    styleContainer={{ marginLeft: 18 }}
                    primaryColor="#01cfb5"
                    textColor="#fff"
                    onClick={() => setEnabledTrack(true, id)}
                  />
                )}
              </S.GroupButtonModal>
            </S.ContainerModal>
          </ModalScreen>
        </>
      )}
    </>
  );
}
