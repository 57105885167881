import styled from 'styled-components';
import { ReactComponent as DeleteIcon } from '~/assets/drops-removefile-logo.svg';
import { ReactComponent as WarningIcon } from '~/assets/flashcards-warning.svg';

const FlexRow = styled.div`
  display: flex;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrap = styled(FlexColumn)`
  gap: 16px;
  width: 100%;
`;

export const Container = styled(FlexColumn)`
  width: 100%;
  padding: 24px 56px;
  border-radius: 8px;
  gap: 24px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px #181a1e14, 0px 0px 2px 0px #181a1e29;
`;

export const Header = styled(FlexRow)`
  flex-wrap: wrap;
  align-items: center;
  gap: 16px 24px;
`;

export const NumberHeaderContainer = styled(FlexRow)`
  gap: 24px;
`;

export const NumberHeader = styled(FlexRow)`
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #8f9db7;
  box-shadow: 0px 0px 0px 4px #3d558233;
  font-size: 12.6px;
  font-weight: 700;
  color: #3d5582;
`;

export const IdLabel = styled(FlexRow)`
  font-size: 12.6px;
  font-weight: 700;
  color: #3d5582;
`;

export const ReportButton = styled.button`
  height: 32px;
  padding: 0px 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  border-radius: 320px;
  border: 2px solid #e2e5ee;
  background: #fff;
  font-size: 12.6px;
  font-weight: 600;
  color: #077859;
  margin-left: ${(props) => (props.isWrapped ? 'none' : 'auto')};

  &:hover {
    background: #daf5f1;
  }
`;

export const DeleteButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #077859;
  background: #fff;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;

  :hover {
    background: #daf5f1;
  }
`;

export const SaveButton = styled.button`
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #0caa87;
  border: none;
  align-items: center;
  justify-content: center;
  border-radius: 320px;

  :hover {
    background: #01cfb5;
  }
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  path {
    fill: #077859;
  }
`;

export const WarningChip = styled(FlexRow)`
  gap: 6px;
  height: 32px;
  padding: 4px 10px;
  border-radius: 100px;
  background: #fcf6dc;
  color: #ef9124;
  justify-content: center;
  align-items: center;
  font-size: 12.6px;
  font-weight: 600;
`;

export const StyledWarningIcon = styled(WarningIcon)`
  path {
    fill: #ef9124;
  }
`;

export const Content = styled(FlexRow)`
  gap: 24px;
`;

export const ContentContainer = styled(FlexColumn)`
  gap: 16px;
  flex-basis: 49%;
`;

export const ContentTitle = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #00205b;
`;

export const SelectContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UpdatedAtContainer = styled(FlexRow)`
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const UpdatedAtLabel = styled.p`
  margin: 0;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const TextModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TextModal = styled.p`
  margin: 0;
  font-size: 20.3px;
  line-height: 27px;
  color: #4f5154;
`;
