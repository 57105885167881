import React, { useState, useEffect } from 'react';
import * as S from './styles';

import Tab from './Tab';

function Tabs({
  children,
  selectedTab,
  stopLine,
  availableTabs,
  withoutContent,
  setOnClick,
  changeStep,
}) {
  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (Array.isArray(children) && children.length > 0) {
      setTabs(children);
      setActiveTab(
        (selectedTab && children[selectedTab] && children[selectedTab].props.label) ||
          children[0].props.label,
      );
    } else if (Object.keys(children).length > 0) {
      setTabs([children]);
      setActiveTab(children.props.label);
    }
  }, [children, selectedTab]);

  function onClickTabItem(tab, onClickHandler) {
    setActiveTab(tab); // Update active tab on click

    if (changeStep) {
      tabs.forEach((elm, index) => {
        if (elm.props.label === tab) {
          changeStep(index);
        }
      });
    }

    if (setOnClick) {
      setOnClick(tab); // Trigger the parent click handler if available
    }

    // Invoke the child-specific onClick handler
    if (onClickHandler) {
      onClickHandler();
    }
  }

  return (
    <S.Styled className="styled-tabs">
      <S.ContainerTab>
        <ol className="tab-list">
          {tabs.map((child) => {
            const { label, badge, disabled, numberBadge, onClick } = child.props;
            return (
              <Tab
                key={label}
                label={label}
                activeTab={activeTab}
                onClick={(tab) => onClickTabItem(tab, onClick)} // Pass the child’s onClick handler
                borderUnselected={stopLine}
                badge={badge}
                disabled={disabled}
                numberBadge={numberBadge}
              />
            );
          })}
        </ol>
      </S.ContainerTab>
      {!withoutContent && (
        <div className="tab-content">
          {tabs.map((child) => {
            if (child.props.label !== activeTab) return null; // Show content only for active tab
            return child.props.children;
          })}
        </div>
      )}
    </S.Styled>
  );
}

Tabs.defaultProps = {
  selectedTab: 0,
  stopLine: false,
  disabled: false,
};

export default Tabs;
