import React, { useState, useEffect } from 'react';
import { Button } from '@medway-npm/ds-medstar';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as ConfirmIcon } from '~/assets/confirm-icon.svg';
import { ReactComponent as ImageIcon } from '~/assets/image-icon.svg';
import Loader from '~/components/Loader';
import { useTagById } from '~/hooks-querys/tag';
import * as S from './styles';

function QuestionDetail({
  question,
  loading,
  close,
  goBack,
  goNext,
  disableBackButton,
  disableNextButton,
}) {
  const [selectedTag, setSelectedTag] = useState(null);
  const [selectedCfa, setSelectedCfa] = useState(null);

  const { data: tag, isFetching: fetchingTag } = useTagById(selectedTag?.id, {
    config: {
      enabled: !!selectedTag?.id,
      retry: false,
    },
  });

  const { data: cfa, isFetching: fetchingCfa } = useTagById(selectedCfa?.id, {
    config: {
      enabled: !!selectedCfa?.id,
      retry: false,
    },
  });

  useEffect(() => {
    if (!question) return;

    setSelectedTag(question.subtags?.find((subtag) => subtag.tag_type === 'f'));
    setSelectedCfa(question.subtags?.find((subtag) => subtag.tag_type === 'c'));
  }, [question]);

  return (
    <>
      <S.Container>
        {loading ? (
          <S.LoaderContainer>
            <Loader />
          </S.LoaderContainer>
        ) : (
          <>
            <S.Header>
              <CloseIcon onClick={close} style={S.CloseButtonStyle} />
              <S.TitleContainer>
                <S.Title>
                  [{question.id}] {question.institution_name} - {question.year} -{' '}
                  {question.question_number}
                </S.Title>
                <S.SubtitleContainer>
                  {tag && <S.Subtitle>{`[Tema/Foco] ${tag?.name}`}</S.Subtitle>}
                  {cfa && <S.Subtitle>{`[CFA] ${cfa?.name}`}</S.Subtitle>}
                </S.SubtitleContainer>
              </S.TitleContainer>
              <S.HeaderBottonContent>
                <S.ImageAndButtonContainer>
                  {question?.images?.length > 0 && (
                    <S.HasImageContainer>
                      <ImageIcon />
                      <S.HasImageText>Contém imagem</S.HasImageText>
                    </S.HasImageContainer>
                  )}
                  <Button
                    size="small"
                    startIcon={<S.StyledExternalLink />}
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_URL}/question/${question.id}/text`,
                        '_blank',
                      )
                    }
                  >
                    Ver na íntegra
                  </Button>
                </S.ImageAndButtonContainer>

                <S.PaginationButtonsContainer>
                  <S.PaginationButton
                    onClick={() => goBack(question.id)}
                    disabled={disableBackButton(question.id)}
                  >
                    <S.StyledChevronLeft />
                    Anterior
                  </S.PaginationButton>
                  <S.PaginationButton
                    onClick={() => goNext(question.id)}
                    disabled={disableNextButton(question.id)}
                  >
                    Próxima
                    <S.StyledChevronRight />
                  </S.PaginationButton>
                </S.PaginationButtonsContainer>
              </S.HeaderBottonContent>
            </S.Header>

            <S.Content>
              <S.ContentText>{question.content}</S.ContentText>
              {question?.main_explanation && question?.question_type === 'd' && (
                <>
                  <S.CommentTitle>Resposta comentada</S.CommentTitle>
                  <S.TextExplanation
                    dangerouslySetInnerHTML={{ __html: question.main_explanation }}
                  />
                </>
              )}
              {question?.options?.length > 0 && question?.question_type === 'o' && (
                <S.OptionsContainer>
                  {question?.options?.map((option) => {
                    return (
                      <S.OptionContainer isCorrect={question?.correct_options.includes(option.id)}>
                        {question?.correct_options.includes(option.id) && (
                          <S.CorrectTag>
                            Opção correta <ConfirmIcon />
                          </S.CorrectTag>
                        )}
                        <S.OptionLetter>{option?.letter}</S.OptionLetter>
                        <S.OptionText>{option?.content}</S.OptionText>
                      </S.OptionContainer>
                    );
                  })}
                </S.OptionsContainer>
              )}
            </S.Content>
          </>
        )}
      </S.Container>
    </>
  );
}

export default QuestionDetail;
