import { DefaultUsersAPICMS, TypeActivityAPICMS } from '~/services/apiCallsCMS';
import { Institution } from '~/services/apiCalls/institution';

export const transformOptions = (item) => {
  if (!item?.id || !item?.name) return;

  let returnValues = {
    label: item.verbose || item.name,
    key: item.id,
    value: item.id,
    ...(item?.multiplier && { multiplier: item.multiplier }),
  };

  if (item.parent_tag) {
    returnValues.parentTag = item.parent_tag;
  }

  return returnValues;
};

const renderOptions = (data, page) => {
  return {
    options: data.results.map(transformOptions),
    hasMore: data.next,
    additional: {
      page: page + 1,
    },
  };
};

export const loadDefaultUsers = async (search, loadedOptions, { page }) => {
  const { data } = await DefaultUsersAPICMS.getDefaultUsers({
    search,
    page,
  });

  data.results.forEach((partner) => {
    partner.name = `${partner.name} - ${partner.email}`;
  });
  return renderOptions(data, page);
};

export const loadTypeActivities = async (search, loadedOptions, { page }) => {
  const { data } = await TypeActivityAPICMS.getTypeActivity({
    search,
    page,
  });

  return renderOptions(data, page);
};

export const loadInstitutions = async (search) => {
  const { data } = await Institution.list({
    ordering: 'name',
    no_page: 1,
    ...(search && { search: search }),
  });

  if (data) {
    return data.map(transformOptions);
  }
};
