import styled from 'styled-components';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 32px;
  background-color: #fff;
  width: 100%;
  position: relative;
  box-shadow: 0px 0px 2px rgba(24, 26, 30, 0.16), 0px 2px 4px rgba(24, 26, 30, 0.08);
  position: sticky;
  top: 0;
  z-index: 1189;
  width: calc(100vw - 80px);
  top: 0;
  height: 140px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1288px;
  width: 100%;
`;

export const Info = styled.div`
  display: flex;
  gap: 24px;
`;

export const Thumbnail = styled.div`
  border-radius: 8px;
  width: 86px;
  height: 64px;
  flex-shrink: 0;
  align-self: center;
  background: ${(props) => `url(${props.image})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Title = styled.div`
  display: inline-flex;
  gap: 16px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 28.8px;
  line-height: 38.4px;
  color: #00205b;
  flex-shrink: 0;

  a {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const TitleText = styled.div`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${(props) => (props.limitTitle ? '20vw' : 'none')};
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

export const HelpContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  margin-left: 8px;

  border-radius: 8px;
  cursor: pointer;

  background-color: #3b3fb6;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14.2px;
  line-height: 18.96px;
  color: #00205b;
`;

export const ContainerNoMargin = styled(Container)`
  max-width: unset;
`;
