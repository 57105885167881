import { createGlobalStyle } from 'styled-components';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import '@medway-npm/ds-medstar/build/styles.css';

import './main.scss';

export default createGlobalStyle`

html {
  background: #F5F5FB;
}

html, html a {
  -webkit-font-smoothing: antialiased;
  -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
  text-shadow: rgba(0,0,0,.01) 0 0 1px;
  font-smooth: always;
}

  * {
    margin:  0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  ul {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  ol {
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: #f5f5fb;
    overflow-x: hidden;
    height: 100vh;
    scrollbar-gutter: stable;

    @media (min-height: 600px) {
      min-height: 590px;
    }
  }

  body, input, button {
    font-family: 'Montserrat', sans-serif;
  }

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  .modal {
    display: none;
    position: fixed;
    padding-top: 50px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    z-index:9999999999;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: white;
    padding: 20px;
    margin: auto;
    width: 55%;
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }

  .close-btn {
    float: right;
    color: lightgray;
    font-size: 24px;
    font-weight: bold;
    position:absolute;
    top: 0;
    right: 10px;
    cursor:pointer
  }

  .close-btn:hover {
    color: darkgray;
  }

  .carousel .control-arrow, .carousel.carousel-slider .control-arrow {
    opacity: 1;
  }


  .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height .15s ease-in;
    cursor: pointer;
}


.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height .15s ease-in;
    transition: height .15s ease-in;
    cursor: pointer;
    border-radius: 5px;
}



  .carousel .control-arrow, .carousel.carousel-slider .control-arrow:hover {
    opacity: 1;
  }

  .carousel.carousel-slider {
    overflow: visible;
  }

  .carousel .control-dots {
    bottom: -50px;
  }

  .react-datepicker-popper {
    z-index: 99999 !important
  }

  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }

  .smallEditorAnnotation {
    height: 158px;
    width: 550px;
  }
  .largeEditorAnnotation {
    height: 64vh;
    width: 550px;
  }


/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #00205B;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-moz-selection { /* Code for Firefox */
  color: #00205B;
  background: #01CFB5;

}

::selection {
  color: #00205B;
  background: #01CFB5;

}



`;
