import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MdArrowBack, MdArrowForward, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import Loader from '~/components/Loader';
import SelectWithPagination from '~/components/SelectWithPagination';
import { loadCfas } from '~/pages/Chapters/components/Settings/utils';
import { Section } from '~/services/apiCalls/section';
import { useTagById } from '~/hooks-querys/tag';
import { useSection } from '~/hooks-querys/chapter';
import history from '~/services/history';

import * as S from './styles';

function EditEfa({ changeStep, chapter, editMode = false }) {
  const [selectedSection, setSelectedSection] = useState('');
  const [selectedContent, setSelectedContent] = useState();
  const [selectedCfa, setSelectedCfa] = useState();

  const previewRef = useRef();

  const { data: tagData, isFetching: fetchingTag } = useTagById(selectedContent?.tag, {
    config: {
      enabled: !!selectedContent?.tag && selectedContent?.tag.length > 0,
    },
  });

  const {
    data: sectionData,
    isLoading: loadingSections,
    refetch: refetchSections,
  } = useSection(
    { chapter: chapter?.id },
    {
      config: {
        enabled: !!chapter?.id,
      },
    },
  );

  const transformTag = (tag) => {
    if (!tag?.id || !tag?.name) return;

    return {
      label: tag.name,
      key: tag.id,
      value: tag.id,
      parent_tag: tag.parent_tag,
    };
  };

  async function handleClick() {
    changeStep(3);
  }

  async function handlePreview() {
    previewRef.current = true;
    await handleClick();
    history.push({
      pathname: `/chapter/preview/${chapter?.id}`,
      state: { removeFooter: true, chapter, backTo: `/chapter/edit/${chapter?.id}` },
    });
  }

  const selectSection = (id) => {
    setSelectedSection(id);
    const newContent = sectionData.find((section) => section.id === id);

    setSelectedContent(newContent);
  };

  const handleSelectedCfa = (cfa) => {
    const selectedCfa = cfa ? [cfa?.key] : [];
    setSelectedCfa(cfa);
    Section.update(selectedSection, { tag: selectedCfa })
      .then(() => {
        refetchSections();
      })
      .catch(() => toast.error('Erro ao alterar CFA. Tente novamente mais tarde.'));
  };

  useEffect(() => {
    if (!sectionData || sectionData.length === 0) return;
    if (selectedSection) return;

    setSelectedSection(sectionData[0].id);
    setSelectedContent(sectionData[0]);
  }, [sectionData]);

  useEffect(() => {
    if (!tagData) {
      return setSelectedCfa(null);
    }
    setSelectedCfa(transformTag(tagData));
  }, [tagData]);

  return (
    <>
      {loadingSections ? (
        <Loader />
      ) : (
        <S.Content>
          <S.Container>
            <S.Index>
              <S.Scroll>
                <S.Title>ESTRUTURA DE TÓPICOS</S.Title>
                {sectionData?.map((section) => {
                  return (
                    <>
                      <S.TopicContainer key={section?.id} onClick={() => selectSection(section.id)}>
                        <S.TopicLeftContainer>
                          <S.Tag selected={selectedSection === section.id}>T1</S.Tag>
                          <S.Name selected={selectedSection === section.id}>{section?.name}</S.Name>
                        </S.TopicLeftContainer>
                        {section?.tag?.length > 0 && <S.CfaTag>CFA</S.CfaTag>}
                      </S.TopicContainer>

                      {section?.sub_sections.map((sub_section) => {
                        return (
                          <S.SubtopicContainer key={sub_section?.id}>
                            <S.Tag>T2</S.Tag>
                            <S.Name>{sub_section?.name}</S.Name>
                          </S.SubtopicContainer>
                        );
                      })}
                    </>
                  );
                })}
              </S.Scroll>
            </S.Index>
            <S.TextContainer>
              <S.ScrollTextContainer>
                <S.SelectedTitle>{selectedContent?.name}</S.SelectedTitle>
                <S.SelectedText dangerouslySetInnerHTML={{ __html: selectedContent?.content }} />

                {selectedContent?.sub_sections?.length > 0 &&
                  selectedContent?.sub_sections?.map((sub_section) => {
                    return (
                      <>
                        <S.SubSectionTitle>{sub_section?.name}</S.SubSectionTitle>
                        <S.SelectedText
                          dangerouslySetInnerHTML={{ __html: sub_section?.content }}
                        />
                      </>
                    );
                  })}
              </S.ScrollTextContainer>

              <S.CfaFooter>
                {fetchingTag ? (
                  <Loader text="" size={50} />
                ) : (
                  <>
                    <S.CfaSelectContainer>
                      <SelectWithPagination
                        loadOptions={(search, loadedOptions, page) =>
                          loadCfas(search, loadedOptions, page, {
                            parent_tag: chapter?.tag[0],
                            speciality: chapter?.speciality,
                          })
                        }
                        text={false}
                        backgroundColor="#F2F2F2"
                        value={selectedCfa}
                        onChange={handleSelectedCfa}
                        placeholder="Adicionar CFA"
                        menuPlacement="auto"
                        customNoOptionsMessage="Nenhuma encontrada"
                        singleValueStyle={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          width: '95%',
                        }}
                        controlStyle={{
                          width: '100%',
                          borderRadius: 8,
                          background: '#F1F2F2',
                          color: '#747678',
                        }}
                        placeholderStyle={{ color: '#d2d2d2', paddingLeft: 2 }}
                        height={40}
                        width={'100%'}
                        menuOnTop
                      />
                    </S.CfaSelectContainer>

                    <S.RemoveCfaButton
                      disabled={!selectedCfa}
                      onClick={() => handleSelectedCfa(null)}
                    >
                      <S.StyledDeleteIcon /> Remover CFA
                    </S.RemoveCfaButton>
                  </>
                )}
              </S.CfaFooter>
            </S.TextContainer>
          </S.Container>
          <S.FooterActions>
            <S.ButtonBack
              type="button"
              onClick={() => {
                if (editMode) {
                  history.push('/chapter');
                } else {
                  changeStep({ first: true, second: false });
                }
              }}
            >
              <MdArrowBack size={18} color="#01c3aa" />
              Voltar
            </S.ButtonBack>
            <S.BoxButtons>
              {editMode && (
                <S.ButtonPreview onClick={() => handlePreview()}>
                  Revisão
                  <MdArrowForward size={18} color="#01c3aa" />
                </S.ButtonPreview>
              )}
              <S.ButtonSave onClick={() => handleClick()}>
                {`${editMode ? 'Salvar' : 'Continuar'}`}
                {editMode ? (
                  <MdSave size={18} color="#FFF" />
                ) : (
                  <MdArrowForward size={18} color="#fff" />
                )}
              </S.ButtonSave>
            </S.BoxButtons>
          </S.FooterActions>
        </S.Content>
      )}
    </>
  );
}

export default EditEfa;
