import styled from 'styled-components';
import { ReactComponent as ChevronRightIcon } from '~/assets/drops-chevron-right-blue.svg';
import { ReactComponent as ExternalLinkComponent } from '~/assets/external-link.svg';

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled(FlexColumn)`
  height: 100%;
  width: 100%;
  border-left: 1px solid #dadadb;
`;

export const Header = styled(FlexColumn)`
  position: relative;
  gap: 24px;
  padding: 48px 56px 32px;
  border-bottom: 1px solid #dadadb;
`;

export const Title = styled.h1`
  margin: 0;
  font-size: 25.6px;
  font-weight: 600;
  line-height: 34.2px;
  color: #00205b;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #4f5154;
`;

export const CloseButtonStyle = {
  position: 'absolute',
  top: '16px',
  right: '16px',
  fill: '#ACACAE',
  cursor: 'pointer',
  width: '24px',
  height: '24px',
};

export const Content = styled(FlexColumn)`
  gap: 24px;
  padding: 40px 56px;
  overflow-y: scroll;
`;

export const ContentText = styled(FlexColumn)`
  color: #00205b;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 32.4px;
`;

export const CommentTitle = styled(FlexColumn)`
  color: #00205b;
  font-family: Montserrat;
  font-size: 20.3px;
  font-style: normal;
  font-weight: 600;
  line-height: 36.5px; /* 179.803% */
`;

export const TextContainer = styled(FlexColumn)`
  gap: 16px;
`;

export const ContentTitle = styled.h1`
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #00205b;
`;

export const ContentSubtitle = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 21.3px;
  color: #00205b;
`;

export const LoaderContainer = styled(FlexColumn)`
  height: 90vh;
  justify-content: center;
  align-items: center;
`;

export const TextExplanation = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 24px;
  color: #00205b;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const OptionLetter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0px;
  width: 40px;
  height: 32px;
  border: 2px solid #e5e8ee;
  border-radius: 8px;
  font-weight: 700;
  font-size: 12.6px;
  line-height: 17px;
  color: #00205b;
`;

export const OptionText = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 21px;
  color: #00205b;
`;

export const OptionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  border-radius: 8px;
  border: ${(props) => (props.isCorrect ? '2px solid #0CBA66' : '2px solid #e5e8ee')};
`;

export const CorrectTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 4px;
  position: absolute;
  height: 26px;
  right: 20px;
  top: -13px;
  background: #0cba66;
  border-radius: 1000px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #ffffff;
`;

export const HeaderBottonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HasImageText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: #00205b;
`;

export const HasImageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PaginationButtonsContainer = styled.div`
  display: flex;
  alignm-items: center;
  justify-content: space-between;
`;

export const PaginationButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  height: 40px;
  border: 2px solid #e5e8ee;
  border-radius: 320px;
  font-weight: 600;
  font-size: 14.2px;
  line-height: 19px;
  color: #00205b;
  background: #fff;

  :hover {
    border-color: #b7bed7;
  }
  :disabled {
    border-color: #e5e8ee;
    color: #d1d7e1;
    cursor: default;

    path {
      fill: #d1d7e1;
    }
  }
`;

export const StyledChevronRight = styled(ChevronRightIcon)`
  width: 20px;
  height: 20px;
`;

export const StyledChevronLeft = styled(StyledChevronRight)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
`;

export const ImageAndButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const StyledExternalLink = styled(ExternalLinkComponent)`
  path {
    fill: #fff;
  }
`;
