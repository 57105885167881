import React from 'react';

import * as S from './styles';

function HeaderList({ title, children }) {
  return (
    <S.HeaderContainer>
      <S.HeaderStyle>{title}</S.HeaderStyle>
      {children}
    </S.HeaderContainer>
  );
}
export default HeaderList;
