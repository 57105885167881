import { useQuery } from '@tanstack/react-query';
import { Section } from '~/services/apiCalls/section';

const getSections = async (params) => {
  const response = await Section.list(params);
  return response.data.results;
};

export default function useSection(params, options) {
  return useQuery({
    queryKey: ['section', params],
    queryFn: () => getSections(params),
    staleTime: 60 * 1000,
    ...options?.config,
  });
}
