/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { IoChevronDownSharp } from 'react-icons/io5';
import { BiPlusCircle } from 'react-icons/bi';
import { ReactComponent as ExchanegIcon } from '~/assets/exchange-icon.svg';
import FilterDropdown from './components/FilterDropdown';
import SelectWithPagination from '~/components/SelectWithPagination';
import SearchInputMaterialUi from '~/components/SearchInputMaterialUi';
import Loader from '~/components/Loader';
import ButtonTextWithPopper from '~/components/ButtonTextWithPopper';
import HeaderComponent from '~/components/HeaderComponent';
import ModalConfirmation from '~/components/ModalConfirmation';
import FilterChips from '~/components/FilterChips';
import DatePicker from '~/components/DatePickerComponent';

import { useListTasks } from '~/hooks-querys/tasks';

import TaskList from './components/TaskList';
import history from '~/services/history';
import OffCanvas from './components/Offcanvas';
import { InputStyle } from '../Financial/styles';
import { Mixpanel } from '~/services/analytics';
import { TaskAPICMS } from '~/services/apiCallsCMS';
import { FILTERS_INITIAL_STATE, AGGREGATION, ORDERING, ACTIONS_LIST } from './variables';
import { STATUS_OPTIONS } from '~/utils/variables';
import { loadDefaultUsers } from './components/FilterDropdown/utils';
import { useWindowSize, checkIfArrayContainsAnother } from '~/utils/util';
import * as S from './styles';
import SelectComponentMaterialUi from '~/components/SelectComponentMaterialUi';

function Activities() {
  const user = useSelector((state) => state.auth.user);
  const managePage = user.permissions.includes('manage_activities');
  const { height } = useWindowSize();
  const headerRef = useRef();
  const [formsData, setFormsData] = useState(FILTERS_INITIAL_STATE);
  const [search, setSearch] = useState();
  const [savedSearch, setSavedSearch] = useState();
  const [serchWithKey, setSearchWithKey] = useState(false);
  const [listParams, setListParams] = useState({ aggregate: 'status', ordering: 'deadline' });
  const [selectedAggregation, setSelectedAggregation] = useState('Status');
  const [selectedOrdering, setSelectedOrdering] = useState('Prazo');
  const [hasHeader, setHasHeader] = useState(height > 700);
  const [openOffcanvas, setOpenOffcanvas] = useState(false);
  const [offcanvasId, setOffcanvasId] = useState();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [filterChips, setFilterChips] = useState([]);
  const [helperChecked, setHelperChecked] = useState([]);
  const [childrenChecked, setChildrenChecked] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [selectedSecondAction, setSelectedSecondAction] = useState(null);
  const [selectedNewPrice, setSelectedNewPrice] = useState();
  const [showActionConfirmationModal, setShowActionConfirmationModal] = useState(false);
  const [actionModalInfo, setActionModalInfo] = useState({
    title: '',
    message: '',
    buttonText: '',
    icon: '',
  });
  const actionsValues = managePage
    ? ACTIONS_LIST
    : ACTIONS_LIST.filter((action) => action.value === 'change_status');

  const {
    data: tasksData,
    isFetching: fetchingTasksData,
    refetch: refetchTasks,
  } = useListTasks(listParams);

  const location = useLocation();

  useEffect(() => {
    if (location.state?.refetch) {
      refetchTasks();
    }
  }, [location.state, refetchTasks]);

  const handleSearch = () => {
    if (search === savedSearch) return;
    if (serchWithKey) {
      setSearchWithKey(false);
      return;
    }
    setSavedSearch(search);
    Mixpanel.track("Clicou 'Lista de atividades - Buscar por palavras'", {
      Pesquisar: search,
    });
    return setListParams({ ...listParams, search: search });
  };

  const handleSearchKey = (event) => {
    if (search === savedSearch) return;

    if (event.key === 'Enter') {
      setSearchWithKey(true);
      handleSearch();
    }
  };

  const handleAggregation = (item) => {
    setListParams({ ...listParams, aggregate: item.key });
    Mixpanel.track("Clicou 'Lista de atividades - Agrupar por'", {
      'Agrupamento por': item.label,
    });
    resetAllCheckboxes();
    return setSelectedAggregation(item.label);
  };

  const handleOrdenation = (item) => {
    setListParams({ ...listParams, ordering: item.key });
    Mixpanel.track("Clicou 'Lista de atividades - Ordenar por'", {
      'Ordenação por': item.label,
    });
    return setSelectedOrdering(item.label);
  };

  const onFilter = () => {
    setFilterChips([]);
    const statusKeys = Object.keys(formsData.selectedStatus);
    const statusArray = statusKeys.filter(function (key) {
      return formsData.selectedStatus[key];
    });

    const newParams = {
      ...(formsData.selectedActivity && {
        activity__type_activity: formsData.selectedActivity.value,
      }),
      ...(formsData.selectedSpeciality && {
        speciality_id: formsData.selectedSpeciality.value,
      }),
      ...(formsData.selectedResidencyDegree && {
        residency_degree_id: formsData.selectedResidencyDegree.value,
      }),
      ...(formsData.selectedPartner && {
        responsible: formsData.selectedPartner.value,
      }),
      ...(formsData.selectedInstitutions.length > 0 && {
        institution_id__in: formsData.selectedInstitutions.map((institution) => institution.value),
      }),
      ...(formsData.selectedTag && {
        tag_id: formsData.selectedTag.value,
      }),
      ...(statusArray.length > 0 && {
        status__in: statusArray,
      }),
      ...(formsData.selectedInitialDate && {
        deadline_after: format(new Date(formsData.selectedInitialDate), 'yyyy-MM-dd'),
      }),
      ...(formsData.selectedFinalDate && {
        deadline_before: format(new Date(formsData.selectedFinalDate), 'yyyy-MM-dd'),
      }),
    };

    const statusArrayName = statusArray.map((status) => STATUS_OPTIONS[status]);

    Mixpanel.track("Clicou 'Lista de atividades - Filtrar - Filtrar'", {
      'Tipo de atividade': formsData?.selectedActivity ? formsData?.selectedActivity.label : '',
      'Grade área': formsData.selectedSpeciality ? formsData?.selectedSpeciality.label : '',
      'Nivel de residência': formsData.selectedResidencyDegree
        ? formsData?.selectedResidencyDegree.label
        : '',
      Parceiro: formsData.selectedPartner ? formsData?.selectedPartner.label : '',
      'Prazo de início': formsData.selectedInitialDate
        ? format(new Date(formsData.selectedInitialDate), 'yyyy-MM-dd')
        : '',
      'Prazo de término': formsData.selectedFinalDate
        ? format(new Date(formsData.selectedFinalDate), 'yyyy-MM-dd')
        : '',
      'Instituição de Ensino Superior':
        formsData.selectedInstitutions.length > 0
          ? formsData.selectedInstitutions.map((institution) => institution.label)
          : '',
      Status: statusArrayName.map((status) => status.label),
      'Tema/Foco': formsData.selectedTag ? formsData?.selectedTag.label : '',
    });

    setListParams({ ...listParams, ...newParams });

    Object.keys(formsData).forEach(function (key, index) {
      if (formsData[key]) {
        switch (key) {
          case 'selectedActivity':
          case 'selectedSpeciality':
          case 'selectedResidencyDegree':
          case 'selectedPartner':
          case 'selectedTag':
            return setFilterChips((filterChips) => [
              ...filterChips,
              { key: key, label: formsData[key].label },
            ]);
          case 'selectedInitialDate':
          case 'selectedFinalDate':
            return setFilterChips((filterChips) => [
              ...filterChips,
              { key: key, label: format(new Date(formsData[key]), 'dd/MM/yyyy') },
            ]);
          case 'selectedInstitutions':
            return formsData[key].map((item) => {
              setFilterChips((filterChips) => [
                ...filterChips,
                { key: key, label: item.label, value: item.value },
              ]);
            });
          case 'selectedStatus':
            return statusArrayName.map((status) => {
              setFilterChips((filterChips) => [
                ...filterChips,
                { key: key, label: status.label, value: status.key },
              ]);
            });
        }
      }
    });
  };

  const handleChangeStatus = async () => {
    Mixpanel.track("Clicou 'Lista de atividades - Status'", {
      'Status atribuído': STATUS_OPTIONS[selectedStatus].label,
    });

    const data = { status: selectedStatus };

    if (selectedStatus === STATUS_OPTIONS.c.key) {
      data['ended_at'] = new Date();
    }
    await TaskAPICMS.update(selectedActivityId, data);
    refetchTasks();
    setShowModalConfirmation(false);
  };

  const openConfirmationModal = (status, activityId) => {
    setSelectedStatus(status);
    setSelectedActivityId(activityId);
    setShowModalConfirmation(true);
  };

  const handleClear = () => {
    return setListParams({ aggregate: listParams.aggregate, ordering: listParams.ordering });
  };

  const handleUpdatePartner = (activityId, responsible) => {
    TaskAPICMS.update(activityId, { responsible: responsible }).then(() => refetchTasks());
  };

  const handleDeleteActivity = (activityId) => {
    TaskAPICMS.delete(activityId).then(() => refetchTasks());
  };

  const handleAddNew = () => {
    Mixpanel.track("Clicou 'Lista de atividades - Adicionar nova'");
    history.push('/create-activity');
  };

  const handleOffCanvas = (activityId, activityName) => {
    Mixpanel.track("Clicou 'Lista de atividades - Abrir Off-Canvas'", {
      'Nome da atividade': activityName,
    });
    setOffcanvasId(activityId);
    setOpenOffcanvas(true);
  };

  const checkAllPerHelper = (event, helper) => {
    const idsToUpdate = tasksData[helper]?.activities.map((activity) => activity.id);
    if (!event) {
      const updatedHelperChecked = helperChecked.filter((value) => value !== helper);
      setHelperChecked(updatedHelperChecked);

      const updatedIdsChecked = childrenChecked.filter((child) => {
        return !idsToUpdate.includes(child);
      });

      setChildrenChecked(updatedIdsChecked);
      return;
    }

    const noDuplicates = idsToUpdate.filter((val) => !childrenChecked.includes(val));
    setChildrenChecked((childrenChecked) => [...childrenChecked, ...noDuplicates]);
    setHelperChecked((helperChecked) => [...helperChecked, helper]);
  };

  const checkChildPerId = (event, id, index) => {
    if (!event) {
      const updatedIdsChecked = childrenChecked.filter((value) => value !== id);
      setChildrenChecked(updatedIdsChecked);

      const updatedHelperChecked = helperChecked.filter((value) => value !== index);
      setHelperChecked(updatedHelperChecked);
      return;
    }

    const allChildrenFromParent = tasksData[index].activities.map((activity) => activity.id);
    const updatedIdsChecked = [...childrenChecked, id];

    if (checkIfArrayContainsAnother(updatedIdsChecked, allChildrenFromParent)) {
      setHelperChecked((helperChecked) => [...helperChecked, index]);
    }
    setChildrenChecked((childrenChecked) => [...childrenChecked, id]);
  };

  const resetAllCheckboxes = () => {
    setHelperChecked([]);
    setChildrenChecked([]);
  };

  const handleAction = (event) => {
    setSelectedSecondAction(null);
    setSelectedAction(event.target.value);
    switch (event.target.value) {
      case 'delete':
        setActionModalInfo({
          title: 'Excluir atividades',
          message:
            'Eu confirmo que estou excluindo as atividades selecionadas. Essa ação não poderá ser revertida.',
          buttonText: 'Excluir',
          icon: <S.StyledDeleteIcon />,
        });
        break;
      case 'change_deadline':
        setActionModalInfo({
          title: 'Alterar prazo',
          message: 'Eu confirmo que estou alterando o prazo de todas atividades selecionadas.',
          buttonText: 'Alterar',
          icon: <ExchanegIcon />,
        });
        break;
      case 'change_status':
        setActionModalInfo({
          title: 'Alterar status',
          message: 'Eu confirmo que estou alterando o status de todas atividades selecionadas.',
          buttonText: 'Alterar',
          icon: <ExchanegIcon />,
        });
        break;
      case 'change_responsible':
        setActionModalInfo({
          title: 'Alterar parceiro',
          message:
            'Eu confirmo que estou alterando o parceiro responsável de todas atividades selecionadas.',
          buttonText: 'Alterar',
          icon: <ExchanegIcon />,
        });
        break;
      case 'price_override':
        setActionModalInfo({
          title: 'Atualizar valor da atividade',
          message: 'Eu confirmo que estou atualizando o valor das atividades selecionadas.',
          buttonText: 'Atualizar valores',
          icon: <ExchanegIcon />,
        });
        break;
      default:
        setActionModalInfo({
          title: '',
          message: '',
          buttonText: '',
          icon: '',
        });
        break;
    }
  };

  const bulkUpdateActions = async (action, value, successMessage, errorMessage) => {
    await TaskAPICMS.bulkUpdate({
      ids: childrenChecked,
      action: action,
      ...(value && { value: value }),
    })
      .then(() => {
        toast.success(successMessage);
        refetchTasks();
      })
      .catch(() => toast.error(errorMessage))
      .finally(() => {
        clearSelections();
      });
  };

  const handleSaveAction = () => {
    if (!selectedAction) {
      return toast.error('Selecione uma ação!');
    }

    setShowActionConfirmationModal(true);
    switch (selectedAction) {
      case 'delete':
        break;
      case 'change_deadline':
        if (!selectedSecondAction) {
          return toast.error('Selecione a nova data para alteração.');
        }
        break;
      case 'change_status':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo status para alteração.');
        }
        break;
      case 'change_responsible':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo responsável para alteração');
        }
        break;
      case 'price_override':
        if (!selectedSecondAction) {
          return toast.error('Selecione o valor para alteração.');
        }
        break;
      default:
        break;
    }
  };

  const handleBulkAction = () => {
    setShowActionConfirmationModal(false);
    switch (selectedAction) {
      case 'delete':
        bulkUpdateActions(
          'delete',
          null,
          'Atividades deletadas com sucesso.',
          'Erro ao deletar atividades. Tente novamente mais tarde.',
        );
        break;
      case 'change_deadline':
        if (!selectedSecondAction) {
          return toast.error('Selecione a nova data para alteração.');
        }
        bulkUpdateActions(
          'deadline',
          new Date(selectedSecondAction),
          'Prazos atualizados com sucesso.',
          'Erro ao atualizar prazos. Tente novamente mais tarde.',
        );
        break;
      case 'change_status':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo status para alteração.');
        }
        bulkUpdateActions(
          'status',
          selectedSecondAction,
          'Status atualizados com sucesso.',
          'Erro ao atualizar status. Tente novamente mais tarde.',
        );
        break;
      case 'change_responsible':
        if (!selectedSecondAction) {
          return toast.error('Selecione o novo responsável para alteração');
        }
        bulkUpdateActions(
          'responsible',
          selectedSecondAction.value,
          'Parceiro atualizado com sucesso.',
          'Erro ao atualizar parceiro. Tente novamente mais tarde.',
        );
        break;
      case 'price_override':
        bulkUpdateActions(
          'price_override',
          selectedSecondAction,
          'Valores atualizados com sucesso.',
          'Erro ao atualizar valores. Tente novamente mais tarde.',
        );
        break;
      default:
        break;
    }
  };

  const clearSelections = () => {
    setChildrenChecked([]);
    setHelperChecked([]);
  };

  const deleteChipFilter = (key, label, value) => {
    const newFilterChips = filterChips.filter((chip) => chip.label !== label);
    setFilterChips(newFilterChips);

    switch (key) {
      case 'selectedActivity':
        setListParams({ ...listParams, activity__type_activity: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedSpeciality':
        setListParams({ ...listParams, speciality_id: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedResidencyDegree':
        setListParams({ ...listParams, residency_degree_id: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedPartner':
        setListParams({ ...listParams, responsible: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedTag':
        setListParams({ ...listParams, tag_id: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedInitialDate':
        setListParams({ ...listParams, deadline_after: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedFinalDate':
        setListParams({ ...listParams, deadline_before: null });
        setFormsData({ ...formsData, [key]: null });
        break;
      case 'selectedInstitutions':
        const institutionsArray = listParams?.institution_id__in.filter(
          (institution) => institution !== value,
        );
        const filteredFormsData = formsData[key]?.filter(
          (institution) => institution.label !== label,
        );
        setListParams({ ...listParams, institution_id__in: institutionsArray });
        setFormsData({ ...formsData, [key]: filteredFormsData });
        break;
      case 'selectedStatus':
        const statusArray = listParams.status__in.filter((status) => status !== value);
        setListParams({ ...listParams, status__in: statusArray });
        setFormsData({ ...formsData, [key]: { ...formsData[key], [value]: false } });
        return;
      default:
        return;
    }
  };

  const handlePrice = (event) => {
    let inputValue = event.target.value;

    if (isNaN(selectedSecondAction)) return;

    inputValue = inputValue.replace(/[^0-9]/g, '');
    const numericValue = parseFloat(inputValue) / 100;
    setSelectedSecondAction(numericValue);
    const formattedValue = numericValue.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    setSelectedNewPrice(formattedValue);
  };

  useEffect(() => {
    if (helperChecked.length === 0 && childrenChecked.length === 0) {
      return setShowFooter(false);
    }
    setShowFooter(true);
  }, [helperChecked, childrenChecked]);

  useEffect(() => {
    if (!height) return;
    return setHasHeader(height > 700);
  }, [height]);

  const renderNextAction = () => {
    switch (selectedAction) {
      case 'delete':
        return <></>;
      case 'change_deadline':
        return (
          <S.SecondSelectContainer>
            <DatePicker
              label="Prazo de entrega"
              value={selectedSecondAction}
              handleChange={(event) => setSelectedSecondAction(event)}
              small
              fullWidth
            />
          </S.SecondSelectContainer>
        );
      case 'change_status':
        const statusOptions = Object.keys(STATUS_OPTIONS).map((key) => {
          return {
            value: STATUS_OPTIONS[key].key,
            label: STATUS_OPTIONS[key].label,
            key: STATUS_OPTIONS[key].key,
          };
        });
        return (
          <S.SecondSelectContainer>
            <SelectComponentMaterialUi
              label="Status"
              placeholder="Status"
              values={statusOptions}
              value={selectedSecondAction}
              setSelectedValue={(event) => setSelectedSecondAction(event.target.value)}
              multiple={false}
              id={'select_status'}
              size="small"
            />
          </S.SecondSelectContainer>
        );
      case 'change_responsible':
        return (
          <S.SecondSelectContainer>
            <SelectWithPagination
              height={40}
              name={'select_responsible'}
              value={selectedSecondAction}
              onChange={(item) => setSelectedSecondAction(item)}
              loadOptions={loadDefaultUsers}
              placeholder="Parceiro"
              singleValueStyle={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '95%',
              }}
              asyncPaginateKey={'async-paginate-select_responsible'}
              menuOnTop
            />
          </S.SecondSelectContainer>
        );
      case 'price_override':
        return (
          <S.SecondSelectContainer>
            <InputStyle>
              <input
                type="text"
                onChange={handlePrice}
                placeholder="Novo valor"
                value={selectedNewPrice && selectedNewPrice}
              />
            </InputStyle>
          </S.SecondSelectContainer>
        );
      default:
        return;
    }
  };
  return (
    <>
      {hasHeader && (
        <HeaderComponent
          title="Atividades"
          description="Crie e gerencie todas atividades do time de parceiros"
          action={handleAddNew}
          actionText="Adicionar Nova"
          permissions={['manage_activities', 'add_course']}
          noMargin
        />
      )}
      <S.Wrap>
        {managePage && !showFooter && (
          <S.FloatingAddButton hasHeader={hasHeader} onClick={handleAddNew}>
            <BiPlusCircle color="#fff" size={28} />
          </S.FloatingAddButton>
        )}
        <S.Header hasHeader={hasHeader} ref={headerRef}>
          <S.HeaderContainer>
            <S.HeaderElementsContainer>
              <SearchInputMaterialUi
                label="Buscar por palavra"
                id="search"
                setSelectedValue={setSearch}
                handleBlur={handleSearch}
                value={search}
                size="small"
                handleKeydown={handleSearchKey}
                textfieldStyle={{ width: '380px' }}
              />
              <FilterDropdown
                formsData={formsData}
                setFormsData={setFormsData}
                handleFilter={onFilter}
                clearFilters={handleClear}
              />
            </S.HeaderElementsContainer>
            <S.HeaderElementsContainer>
              <ButtonTextWithPopper
                title={`Agregar por: ${selectedAggregation}`}
                icon={<IoChevronDownSharp size={16} color="#00205B" />}
              >
                <S.DropdownMenu>
                  {AGGREGATION.map((item) => {
                    return (
                      <S.DropdownMenuItem onClick={() => handleAggregation(item)}>
                        {item.label}
                      </S.DropdownMenuItem>
                    );
                  })}
                </S.DropdownMenu>
              </ButtonTextWithPopper>
              <ButtonTextWithPopper
                title={`Ordenar por: ${selectedOrdering}`}
                icon={<IoChevronDownSharp size={16} color="#00205B" />}
              >
                <S.DropdownMenu>
                  {ORDERING.map((item) => {
                    return (
                      <S.DropdownMenuItem onClick={() => handleOrdenation(item)}>
                        {item.label}
                      </S.DropdownMenuItem>
                    );
                  })}
                </S.DropdownMenu>
              </ButtonTextWithPopper>
            </S.HeaderElementsContainer>
          </S.HeaderContainer>
          {filterChips.length > 0 && (
            <S.HeaderElementsContainer>
              <FilterChips data={filterChips} handleDelete={deleteChipFilter} />
            </S.HeaderElementsContainer>
          )}
        </S.Header>
        <S.Container headerHeight={headerRef.current?.offsetHeight || 0}>
          {fetchingTasksData ? (
            <Loader />
          ) : (
            <TaskList
              tasks={tasksData}
              aggregate={listParams.aggregate}
              handleChangeStatus={openConfirmationModal}
              handleUpdatePartner={handleUpdatePartner}
              handleDeleteActivity={handleDeleteActivity}
              handleOffCanvas={handleOffCanvas}
              user={user}
              helperChecked={helperChecked}
              checkAll={checkAllPerHelper}
              childrenChecked={childrenChecked}
              checkChild={checkChildPerId}
            />
          )}
        </S.Container>
        {showFooter && (
          <S.Footer>
            <S.FooterLeft>
              <S.SelectContainer>
                <SelectComponentMaterialUi
                  label="Executar ação"
                  placeholder="Executar ação"
                  values={actionsValues}
                  selectedValue={selectedAction}
                  setSelectedValue={handleAction}
                  multiple={false}
                  id={'select_action'}
                  size="small"
                  limitWidth={true}
                />
              </S.SelectContainer>
              {selectedAction && renderNextAction()}
              <S.SelectedLabel>
                <b>{childrenChecked.length}</b>
                {childrenChecked.length === 1
                  ? ' atividade selecionada'
                  : ' atividades selecionadas'}
              </S.SelectedLabel>
            </S.FooterLeft>
            <S.FooterButtonsContainer>
              <S.ClearButton onClick={clearSelections}>Limpar seleção</S.ClearButton>
              <S.SaveButton onClick={handleSaveAction}>
                <S.StyledArrowFoward />
              </S.SaveButton>
            </S.FooterButtonsContainer>
          </S.Footer>
        )}
      </S.Wrap>
      <OffCanvas
        show={openOffcanvas}
        close={() => setOpenOffcanvas(false)}
        activityId={offcanvasId}
        handleChangeStatus={openConfirmationModal}
        user={user}
      />
      {showModalConfirmation && (
        <ModalConfirmation
          title={
            selectedStatus === STATUS_OPTIONS.c.key ? 'Concluir atividade' : 'Mudança de status'
          }
          message={
            selectedStatus === STATUS_OPTIONS.c.key
              ? 'Eu confirmo que a atividade está finalizada e estou ciente que a atividade está sendo enviada para pagamento.'
              : 'Tem certeza que deseja mudar o status da tarefa?'
          }
          actionText="Confirmar"
          showModal={showModalConfirmation}
          handleClose={() => setShowModalConfirmation(false)}
          handleAction={() => handleChangeStatus()}
        />
      )}

      {showActionConfirmationModal && (
        <ModalConfirmation
          showModal={showActionConfirmationModal}
          handleClose={() => setShowActionConfirmationModal(false)}
          title={actionModalInfo.title}
          message={actionModalInfo.message}
          actionText={
            <>
              {actionModalInfo.icon} {actionModalInfo.buttonText}
            </>
          }
          handleAction={handleBulkAction}
        />
      )}
    </>
  );
}

export default Activities;
