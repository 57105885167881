export const FILTERS_INITIAL_STATE = {
  selectedActivity: null,
  selectedSpeciality: null,
  selectedResidencyDegree: null,
  selectedPartner: null,
  selectedInitialDate: null,
  selectedFinalDate: null,
  selectedInstitutions: [],
  selectedStatus: [],
  selectedTag: null,
};

export const AGGREGATION = [
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Tipo de atividade',
    key: 'activity',
  },
  {
    label: 'Grande Área',
    key: 'speciality_id',
  },
  {
    label: 'Parceiro',
    key: 'responsible',
  },
  {
    label: 'IES',
    key: 'institution_id',
  },
];

export const ORDERING = [
  {
    label: 'Prazo',
    key: 'deadline',
  },
  {
    label: 'Alfabético A-Z',
    key: 'name',
  },
  {
    label: 'Alfabético Z-A',
    key: '-name',
  },
  {
    label: 'Status',
    key: 'status',
  },
];

export const ACTIONS_LIST = [
  { label: 'Excluir', key: 'delete', value: 'delete' },
  { label: 'Alterar prazo', key: 'change_deadline', value: 'change_deadline' },
  { label: 'Alterar status', key: 'change_status', value: 'change_status' },
  { label: 'Alterar parceiro', key: 'change_responsible', value: 'change_responsible' },
  { label: 'Atualizar valor da atividade', key: 'price_override', value: 'price_override' },
];
