import React from 'react';
import { Button } from '@medway-npm/ds-medstar';
import PropTypes from 'prop-types';
import { RiQuestionMark } from 'react-icons/ri';
import { BiPlusCircle } from 'react-icons/bi';
import ProtectedContent from '~/components/ProtectedContent';
import BreadCrumb from '~/components/BreadCrumb';
import * as S from './styles';

export default function HeaderComponent({
  title,
  description,
  image,
  ishtml,
  showHelp,
  onClickHelp,
  action,
  actionText,
  actionIcon,
  permissions,
  cms,
  children,
  chip,
  tags,
  limitTitle,
  noMargin,
}) {
  const renderInformations = () => {
    return (
      <>
        <S.Info>
          {image && <S.Thumbnail image={image} />}
          <S.TextContainer>
            <BreadCrumb />
            <S.TitleContainer>
              <S.Title id="header-component-title">
                <S.TitleText limitTitle={limitTitle}>{title}</S.TitleText>
                {chip}
                {cms && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${process.env.REACT_APP_BASE_URL}/admin/track/track/${cms}`}
                  >
                    (ver no cms)
                  </a>
                )}
                {showHelp && (
                  <S.HelpContainer onClick={onClickHelp}>
                    <RiQuestionMark color="#fff" size={12} />
                  </S.HelpContainer>
                )}
              </S.Title>
              {tags}
            </S.TitleContainer>
            {ishtml ? (
              <S.Description
                id="header-component-description"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            ) : (
              <S.Description id="header-component-description">{description}</S.Description>
            )}
          </S.TextContainer>
        </S.Info>
        <ProtectedContent permissions={permissions}>
          {action && (
            <Button size="large" onClick={action}>
              {actionIcon ? actionIcon : <BiPlusCircle color="#fff" size={22} />}
              {actionText}
            </Button>
          )}
        </ProtectedContent>
        {children}
      </>
    );
  };
  return (
    <S.Wrap>
      {noMargin ? (
        <S.ContainerNoMargin>{renderInformations()}</S.ContainerNoMargin>
      ) : (
        <S.Container>{renderInformations()}</S.Container>
      )}
    </S.Wrap>
  );
}

HeaderComponent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showHelp: PropTypes.bool,
  onClickHelp: PropTypes.func,
  action: PropTypes.func,
  actionText: PropTypes.string,
  permissions: PropTypes.array,
};

HeaderComponent.defaultProps = {
  title: '',
  description: '',
  actionText: '',
  permissions: [],
  showHelp: false,
  onClickHelp: null,
  action: null,
};
